import { createSlice } from "@reduxjs/toolkit";

export const notificationsSliceName = "notifications";

const shape = [
  "title",
  "content",
  "source",

  /*Autogenerated if not already present*/
  "id",
  "created", //
];

/* TODO switch to proper typing*/
const validateShape = (arg) => {
  if (!shape.every((e) => Object.keys(arg).includes(e)))
    throw new Error(
      `Invalid notification shape: expected ${shape}, but got ${Object.keys(
        arg
      )}`
    );
};

/**
 * App (frontend) notifications
 * Marked with `fromApp: true`
 */
export const notificationSlice = createSlice({
  name: notificationsSliceName,
  initialState: [],
  reducers: {
    addAppNotification: (state, { payload }) => {
      validateShape(payload);
      state.push({ ...payload, fromApp: true });
    },
    removeAppNotification: (state, { payload: { id } }) =>
      state.filter((e) => e.id !== id),
  },
});

export const { addAppNotification, removeAppNotification } =
  notificationSlice.actions;

export const selectAppNotifications = (state) => state[notificationsSliceName];
